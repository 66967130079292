<template>
  <sweet-modal ref="modal"
               overlay-theme="dark"
               :enable-mobile-fullscreen="true"
               :width="modalWidth" >
    <!--:hide-close-button="true"-->
    <!--<div class="header-logo">-->
      <!--&lt;!&ndash;<img src="../../assets/img/logo/logo_origin.png" alt="" />&ndash;&gt;-->
      <!--<div class="lp-modal-close unselect" @click="$refs.modal.close()"><i class="material-icons">close</i></div>-->
    <!--</div>-->
    <div class="lp-modal-container">
      <!-- 로그인 -->
      <div v-if="modalType === 'login'" class="modal-container">
        <div class="modal-title">
          <div class="title">로그인</div>
        </div>
        <div class="modal-content">
          <div class="modal-input-form">
            <input type="email"
                   maxlength="50"
                   v-model="loginValue.username" class="sign-modal-input" placeholder="이메일">
            <div class="valid-message" v-if="loginValid.username">{{loginValid.username}}</div>
            <input type="password"
                   maxlength="30"
                   @keyup.enter="login"
                   v-model="loginValue.password" class="sign-modal-input" placeholder="비밀번호">
            <div class="valid-message" v-if="loginValid.password">{{loginValid.password}}</div>
            <button class="btn-primary" @click="login()"
                    :disabled="!loginAble">로그인
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <div class="findpw" @click="modalType='showModal'">비밀번호 찾기</div>
          <div class="signup" @click="modalType='signup'">회원가입</div>
        </div>
      </div>
      <!--비밀번호 찾기-->
      <div v-else-if="modalType === 'showModal'" class="modal-container">
        <div class="modal-title">
          <div class="title">
            비밀번호 재설정
          </div>
        </div>
        <div class="modal-content">
          <div class="modal-input-form">
            <input type="email"
                   maxlength="50"
                   class="sign-modal-input"
                   placeholder="이메일을 입력해주세요" >
            <p style="margin:10px 40px 24px;
                font-size: 12px;
                text-align: left;
                color:#989898;
                width: 280px;
                height: 46px;
                line-height: 26px" >회원가입시 작성한 이메일을 통해 새 비밀번호로 변경할 수 있는  링크가 전송됩니다.
            </p>
          </div>

        </div>
        <div>
          <button class="btn-primary" @click="passwordReset">
            비밀번호 재설정
          </button>
        </div>

      </div>




      <!-- 회원가입 -->
      <div v-else-if="modalType === 'signup'" class="modal-container">
        <div class="modal-title">
          <div class="title">회원가입</div>
        </div>
        <div class="modal-content">
          <div class="modal-input-form">
            <input type="text"
                   maxlength="20"
                   v-model="signupValue.name" class="sign-modal-input" placeholder="이름">
            <input type="text"
                   maxlength="13"
                   :value="signupValue.phone"
                   @input="e => signupValue.phone = e.target.value"
                   @keyup="keyUpPhone"
                   class="sign-modal-input" placeholder="연락처">
            <input type="email"
                   maxlength="50"
                   v-model="signupValue.username" class="sign-modal-input" placeholder="이메일">
            <div class="valid-message" v-if="signupValid.username">{{signupValid.username}}</div>
            <input type="password"
                   maxlength="30"
                   v-model="signupValue.password" class="sign-modal-input" placeholder="비밀번호">
            <div class="valid-message" v-if="signupValid.password">{{signupValid.password}}</div>
            <input type="password"
                   maxlength="30"
                   v-model="signupValue.passwordCheck" class="sign-modal-input" placeholder="비밀번호 확인">
            <div class="valid-message" v-if="signupValid.passwordCheck">{{signupValid.passwordCheck}}</div>

            <div class="policy">
              <label class="lp-checkbox">
                <input type="checkbox" v-model="signupValue.check1" class="sign-modal-input">
                이용약관 동의
              </label>
              <div class="policy-see-all" @click="clickAll('term')">전체보기</div>
            </div>
            <div class="policy">
              <label class="lp-checkbox">
                <input type="checkbox" v-model="signupValue.check2" class="sign-modal-input">
                개인정보 취급방침 동의
              </label>
              <div class="policy-see-all" @click="clickAll('limit')">전체보기</div>
            </div>

            <button class="btn-primary" style="margin-top:22px; margin-bottom:40px;" @click="signup()"
                    :disabled="!signupAble">
              가입
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="modalType === 'email'" class="modal-container">
        <div class="modal-title">
          <div class="title">이메일 인증</div>
        </div>
        <div class="modal-content">
          <img src="../../assets/img/img-email-sent.png" alt="" style="width: 140px">
          <p style="margin:22px 45px 24px 45px;
                    font-size: 14px;
                    text-align: center;
                    color: rgb(57, 62, 70);">
            <span style="color:rgb(99,194,222)">{{signupValue.username}}</span>으로 전송된 이메일을 확인하여 가입을 완료해주세요</p>
          <div style="height: 52px;">
            <button class="btn-primary" :disabled="emailSendCount >= 3" @click="resend()">재전송 요청</button>
          </div>
          <div v-if="emailSendMessage"
               style="color: rgb(203, 73, 69);
                      font-size: 11px;
                      margin: 5px 40px 24px 40px;
                      height:42px;
                      text-align: left;">
            {{emailSendMessage}}
          </div>
        </div>
        <div class="email-footer-space"></div>
        <div class="email-footer">
          <p style="margin-left:40px;
                    margin-right:40px;
                    margin-bottom: 15px;
                    font-size:11px;
                    text-align: left;
                    line-height: 22px;
                    color: rgb(51, 51, 51);">이메일 인증을 받으셔야 회원가입이 완료되며, 확인 메일이 오지 않는 경우 다음과 같이 시도해주세요</p>
          <p style="font-size:11px;
                    text-align: left;
                    margin-left:40px;
                    margin-right:40px;
                    line-height: 20px;
                    color: rgb(152, 152, 152);">-스팸 메일함을 확인합니다. <br>-재전송 요청 버튼을 통해 메일을
            재전송 받으세요 <br>-재전송은 최대 3회까지만 가능합니다.</p>
        </div>
      </div>
      <div v-else class="modal-container">
        <div class="modal-title">
          <div class="title">회원가입이 완료되었습니다</div>
        </div>
        <div class="modal-content">
          <p>런치팩에서 손쉽는고 간편하게<br>원하는 서비스를 만들어 보세요</p>
          <button class="btn-primary" @click="$refs.modal.close()">시작하기</button>
        </div>
      </div>
    </div>
  </sweet-modal>
</template>

<script>
  import {SweetModal} from 'sweet-modal-vue';

  export default {
    components: {
      SweetModal,
    },

    computed: {
      modalWidth() {
        if(window.innerWidth > 420)
          return '500';
        else
          return window.innerWidth;
      }
    },

    data() {
      return {
        isModalVisible:false,
        modalType: 'login',
        loginValue: {
          username: '',
          password: ''
        },
        loginValid: {
          username: '',
          password: '',
        },
        loginAble: false,
        signupValue: {
          name: '',
          phone: '',
          username: '',
          password: '',
          passwordCheck: '',
          check1: false,
          check2: false,
        },
        signupValid: {
          username: '',
          password: '',
          passwordCheck: '',
        },
        resetPassword:{
          username:''
        },
        emailSendCount: 0,
        emailSendMessage: '',
        signupAble: false,
      }
    },
    methods: {
      clickAll(type) {
        if(type==='term') {
          window.open('/term');
        }
        else if(type==='limit') {
          window.open('/limit');
        }
      },
      passwordReset() {
        this.$axios.post('auth/user/reset/password').then(res=>{
          if(res.status===200) {
            this.toast('비밀번호 ')
          }
        })
      },
      cleanData() {
        this.loginValue = {
          username: '',
          password: ''
        };

        this.loginValid = {
          username: '',
            password: '',
        };

        this.signupValue = {
          name: '',
          phone: '',
          username: '',
          password: '',
          passwordCheck: '',
          check1: false,
          check2: false
        };

        this.signupValid = {
          username: '',
          password: '',
          passwordCheck: ''
        };

      },
      showModal(){
        this.isModalVisible = true;
      },

      keyUpPhone() {
        this.signupValue.phone = this.autoHyphenPhone(this.signupValue.phone);
      },
      open(modalType = 'login') {
        this.cleanData();
        this.modalType = modalType;
        this.$refs.modal.open();
      },
      login() {
        this.$axios.post('auth/user/login', this.loginValue).then(res => {
          if (res.status === 200) {
            this.$store.commit('setUser', res.data.user)
            this.setAuthHeader()
            this.$refs.modal.close();
            this.$router.push('mypage/my_service');
          }
        }).catch(error => {
          if (error.response) {
            let res = error.response;
            if (res.status !== 200) {
              this.toast(res.data.detail);
            }
          }
        })
      },
      signup() {
        this.$axios.post('auth/user/signup', this.signupValue).then(res => {
          if (res.status === 200) {
            this.modalType = 'email'
          }
        }).catch(error => {
          if (error.response) {
            let res = error.response;
            this.toast(res.data.message + '이 있습니다.');
          }
        })
      },
      resend() {
        this.emailSendMessage = ''
        this.$axios.get('auth/user/verify/resend?channel=lptest', {
          params: {username: this.signupValue.username}
        }).then(() => {
          this.emailSendCount++
          this.emailSendMessage = '전송했습니다. (' + this.emailSendCount + '번째)'
        })
      },
      checkSignupAble() {
        if (!this.signupValue.username ||
          !this.signupValue.password ||
          !this.signupValue.passwordCheck ||
          !this.signupValue.name ||
          !this.signupValue.phone ||
          !this.signupValue.check1 ||
          !this.signupValue.check2)
          return false

        else if (this.signupValue.password !== this.signupValue.passwordCheck ||
          !this.checkValidation(this.signupValue.username, 'email'))
          return false

        return true
      }
    },

    watch: {
      'loginValue.username'(val) {
        if (this.checkValidation(val, 'email')) {
          this.loginValid.username = ''
        } else {
          this.loginValid.username = ''
        }
        this.loginAble = !!(val && this.loginValue.password);
      },
      'loginValue.password'(val) {
        if (this.loginValue.username===true||this.loginValue.password===true) {
          this.loginValid.password = ''
        }else{
          this.loginValid.password=''
        }
        this.loginAble = !!(val && this.loginValue.username);
      },
      'signupValue.name'() {
        this.signupAble = this.checkSignupAble()
      },
      'signupValue.username'(val) {
        if (this.checkValidation(val, 'email')) {
          this.signupValid.username = ''
        } else {
          this.signupValid.username = '메일 주소를 정확히 입력해주세요'
        }
        this.signupAble = this.checkSignupAble();
      },
      'signupValue.password'(val) {
        if (this.checkPass(val,'password')==false) {
          this.signupValid.password=''
        }else{
          this.signupValid.password = '영문/숫자를 조합하여 8자 이상 입력해주세요'
        }
        this.signupAble = this.checkSignupAble();
      },
      'signupValue.passwordCheck'() {
        if (this.signupValue.password === this.signupValue.passwordCheck) {
          this.signupValid.passwordCheck = ''
        } else {
          this.signupValid.passwordCheck = '비밀번호치가 일치하지 않습니다.'
        }
        this.signupAble = this.checkSignupAble();
      },
      'signupValue.phone'() {
        this.signupAble = this.checkSignupAble();
      },
      'signupValue.check1'() {
        this.signupAble = this.checkSignupAble();
      },
      'signupValue.check2'() {
        this.signupAble = this.checkSignupAble();
      },

      emailSendCount(val) {
        if (val >= 3) {
          this.emailSendMessage = '이메일 인증 횟수를 초과했습니다. 이메일 정보를 확인하여 주시고 회원가입을 다시 진행해주세요'
        }
      }
    }

  }
</script>

<style scoped lang="scss">
  .modal-title {
    .title {
      font-size: 18px;
      color: rgb(57, 62, 70);
      font-weight: 700;
    }
    /*margin: 41.8px 0 30px 0;*/
  }

  .lp-modal-container {
    margin:auto;
    width: 100%;
    height:100%;
  }
  .modal-container{
    margin: 0 auto;
  }

  button.btn-primary {
    width: 280px;
    height: 44px;
    color: rgb(255, 102, 0);
    border-radius: 3px;
    background-color: white;
    border: 1px solid #eaeaea;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  button.btn-primary:disabled {
    color: rgb(160, 160, 160);
    background-color: rgb(247, 247, 247);
  }


  input.sign-modal-input {
    padding: 0;
    outline: none;
    margin: 0;
    box-sizing: border-box;

    &[type="text"], &[type="email"], &[type="password"], &[type="number"] {
      width: 280px;
      font-size: 13px;
      border: 1px solid rgb(230, 230, 230);
      height: 44px;
      border-radius: 3px;
      margin-bottom: 10px;
      padding: 13px;
    }
    &[type="number"]::-webkit-outer-spin-button, &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="checkbox"] {

    }
    &[type="password"]{
      margin-bottom:24px;
    }
  }

  .policy {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 8px 40px;

    label.lp-checkbox {
    }

    .policy-see-all {
      color: rgb(255, 162, 132);
      cursor: pointer;
      &:hover {
        color: rgb(255, 102, 0);
      }
    }

  }

  .modal-footer {
    display: flex;
    padding: 40px 77px 0px 77px;
    justify-content: center;
    font-size: 14px;
    color: rgb(51, 51, 51);

    div {
      font-weight: bold;
      cursor: pointer;

    }

    div.signup {
      color: rgb(255, 87, 34);
      /*padding-left: 148px;*/
    }
  }
  .findpw{
    margin-right: 148px;
  }

  .valid-message {
    font-size: 12px;
    text-align: left;
    padding-left: 5px;
    color: #cc4945;
    margin: -8px 0 5px 35px;
  }

  .email-footer-space {
    /*height: 132px;*/
  }

  .email-footer {
    padding: 24px 16px;
    text-align: center;
    background: #f5f5f5;
    /*position: absolute;*/
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }

  /*.header-logo{
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    background-color: #000000;
  }

  .header-logo img {
    width: 176px;
    padding: 2px;
    padding-top: 8px;
  }*/

  .lp-modal-close {
    position: absolute;
    right: 13px;
    top: 13px;
    z-index: 10;
    text-align: center;
    width: 30px;
    height: 25px;
  }

  .lp-modal-close i {
    color: white;
  }

  @media (max-width: 600px) {
    .lp-modal-container {
      width: calc(100% - 40px);
      height: calc(100vh - 126px);
    }
    .modal-footer {
      padding: 27px;
    }
    .signup {
      padding-left: 136px;
      .findpw {
        margin-right: 144px;
      }
      /*div.signup{*/
      /*padding-left:144px;*/
      /*}*/
      .modal-input-txt {
        padding: 0 85px 0 91px;
      }
    }

    @media (min-width: 601px) {
      .lp-modal-container {

      }
    }
  }

</style>
