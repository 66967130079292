<template>
  <div>
    <!-- 헤더 -->
    <div class="header-container flex">
      <div class="container flex header" style="margin: 0">
        <div id="logo" @click="$router.push('/home')" style="flex-shrink: 0">
          <img src="static/img/img_logo.svg" alt="" class="unselect">
        </div>
        <div class="flex menu">
          <div class="header-menu table">
            <div v-for="(menu,idx) in menus" :key="'menu-'+idx"
                 class="table-cell"
                 @click="clickMenu(menu)"
                 :style="cellStyle(menu)">
              {{ menu.name }}
              <!--<div v-if="isCurrentMenu(menu)"
                   style="position: absolute;top: auto;bottom: 0;left: 50%;"
                   class="tri"></div>-->
            </div>

            <!-- 유저  -->
            <div class="table-cell" style="position: relative; width:20%">
              <div v-if="isLogin" class="user-region">
                <img class="account_outline"
                     style="color:white"
                     src="../assets/account_outline.png"
                     alt="">{{ userName }}
                <img class="arrow_down"
                     style="color:white"
                     src="../assets/arrow_down.png"
                     alt="">

                <!-- 마이페이지 메뉴 -->
                <div class="lp-mypage-menu-bg">
                  <div class="lp-mypage-menu">
                    <div class="lp-mypage-menu-item unselect" @click="clickSite">마이페이지</div>
                    <div class="lp-mypage-menu-item unselect" @click="clickLogout">로그아웃</div>
                  </div>
                </div>
              </div>
              <div v-else @click="$router.push('/signin')" class="lp-login">로그인</div><!--@click="$router.replace('/signin')"-->
            </div>
            <div class="table-cell" v-if="!isMobile">
              <button class="button is-primary-reverse margin-left-28" @click="$router.push('/quickbuilder')">내 서비스 개설</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 로딩 -->
    <div class="lp-loading" v-if="loadingStatus">
      <div class="flex" style="justify-content: center;">
        <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      </div>
    </div>
    <transition name="fade">
      <router-view></router-view>
    </transition>

    <!-- 푸터 -->
    <lp-footer></lp-footer>

    <lp-sign-modal ref="signModal"></lp-sign-modal>
    <lp-toast></lp-toast>

    <!-- 주문제작 신청 -->
    <sweet-modal ref="reqModal">
      <lp-service-inquiry-modal @closeModal="$refs.reqModal.close()"></lp-service-inquiry-modal>
    </sweet-modal>

    <!-- Top 버튼 -->
    <transition name="fade">
      <div @click="clickTop" class="btn-top unselect" v-show="vTop"><i class="material-icons" style="font-size:30px">arrow_drop_up</i><br>TOP</div>
    </transition>
  </div>
</template>

<script>
  import LpFooter from "./component/LpFooter";
  import LpToast from "./component/LpToast";
  import LpSignModal from "./modal/LpSignModal";
  import { SweetModal } from 'sweet-modal-vue';
  import LpServiceInquiryModal from "./modal/LpServiceInquiryModal";

  export default {
    components: {
      LpServiceInquiryModal,
      SweetModal,
      LpSignModal,
      LpFooter,
      LpToast
    },

    created() {
      this.setAuthHeader();
      this.$store.commit('setLoading', false);
      this.$root.$on('login', ()=>{
        this.$refs.signModal.open();
        // this.$router.replace();
      })
      window.addEventListener('scroll', () => {
        let top = document.getElementsByTagName('html')[0].scrollTop;
        if(top > 300) {
          this.vTop = true;
        } else {
          this.vTop = false;
        }
      });
    },

    watch: {
      '$store.getters.openLogin'(n) {
        if(n) {
          this.$refs.signModal.open();
          // this.$router.replace();
          this.$store.commit('setOpenLogin', false);
        }
      }
    },

    computed: {
      loadingStatus(){
        if (this.$store.getters.loading) return true;
        else return false;
      },
      userName() {
        if(this.$store.getters.user.user_id>0)
          return this.$store.getters.user.name;
        else
          return '';
      }
    },
    data() {
      return {
        vTop: false,
        menus: [
          {
            name: '서비스안내',
            path: 'home'
          },
          {
            name: '테마',
            path: 'store'
          },
          {
            name: '요금제',
            path: 'price'
          },
          {
            name: '주문제작 신청',
            path: 'req'
          },
          /*{
            name: '테마 선주문 신청',
            path: 'preorder'
          },*/
          // {
          //   name: '고객사례',
          //   path: 'customer'
          // },
          {
            name: '고객센터',
            path: 'cs'
          },
          /*{
            name:'닉네임명',
            path:'mypage/my_service',
            isLogin: true
          },
          {
            name: '로그인',
            path: 'login',
            isLogin: false
          }*/
        ]
      }
    },
    methods: {
      clickTop() {
        document.getElementsByTagName('html')[0].scrollTop = 0;
      },
      isCurrentMenu(menu) {
        return this.$route.path.indexOf(menu.path) > -1 ? true : false;
      },
      cellStyle(menu) {
        // console.log(menu)
        let val = 0;
        if(menu.name.length<3) val = 10;
        else if(menu.name.length<5) val = 15;
        else if(menu.name.length<7) val = 20;
        else if(menu.name.length<10) val = 25;
        else if(menu.name.length>=11) val = 27;
        let deco = { width: `${val}%`};
        if(this.$route.path.replace('/', '') === menu.path) {
          deco.color = 'white';
          deco.fontWeight = 'bold';
        }
        else if((this.$route.path.replace('/','')).search(menu.path) > -1){
          deco.color = 'white';
          deco.fontWeight = 'bold';
        }
        else
          deco.color = '#c3c4c7';

        return deco;
      },
      // 메뉴 클릭
      clickMenu(menu) {
        if(menu.path === 'req') {
          this.setGa('상단메뉴 (주문제작 신청)', '클릭', '주문제작 신청', 1);
          this.$refs.reqModal.open();
        } else {
          this.setGa(`상단 메뉴 (${menu.name})`, '클릭', menu.path, 1);
          this.$router.push('/'+menu.path);
        }
      },
      /*checkLogin(menu) {
        if(menu.isLogin === undefined)
          return true;
        else {
          if(!menu.isLogin) {
            if(!this.isLogin)
              return true;
            else
              return false;
          }
          else {
            if(!this.isLogin)
              return false;
            else
              return true;
          }
        }
      },*/
      // 사이트 바로가기
      clickSite() {
        this.$router.push('/mypage/my_service');
      },
      // 로그아웃
      clickLogout() {
        this.$axios.post('/auth/user/logout?token='+this.$store.getters.user.token)
          .then(res => {
            if(res.status === 200) {
              this.toast('로그아웃 되었습니다.');
              this.logout();
              this.$router.replace('/home');
            }
          });
      }
    }
  }
</script>

<style scoped>
  .header-container {
    background-color:#393e46;
    /*background-color:#ffffff;*/
    align-items: center;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    justify-content: center;
  }

  .header #logo img {
    /*width: 145px;
    height: 34px;*/
    margin-top: 16px;
  }

  .lp-mypage-menu-bg {
    display: none;
  }

  .user-region{
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-region:hover .lp-mypage-menu-bg{
    position: fixed;
    z-index:100;
    display: block;
    top: 44px;
    padding-top: 12px;
    width: 200px;
    transform: translateX(-25%);
  }

  .lp-mypage-menu {
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    width: 200px;
  }

  .lp-mypage-menu-item {
    text-align: left;
    padding: 12px;
    width: 100%;
  }

  .lp-mypage-menu-item:last-child {
    border-top: 1px solid #eee;
  }

  .lp-mypage-menu-item:hover {
    color: #333;
  }

  .lp-mypage:hover .lp-mypage-menu-bg{
    display: block;
  }

  .arrow_down{
    width: 10px;
    height: 10px;
    margin-left: 8px;
  }
  .account_outline{
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .lp-login{
    color: #c3c4c7;
  }
  .lp-login:hover{
    color:white;
    /*font-weight: bold;*/
  }
  .lp-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
  }

  .lp-loading i {
    position: fixed;
    top: 50%;
  }

  .tri{
    width: 0px;
    height: 0px;
    border-bottom: 8px solid white;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    transform: translateX(-50%);
    /*-ms-border-bottom: 8px solid white;*/
    /*-ms-border-right: 8px solid transparent;*/
    /*-ms-border-left:8px solid transparent;*/
    -ms-transform: translateX(-50%);
  }

  .table-cell {
    position: relative;
  }


  .btn-top {
    font-size: 12px;
    position: fixed;
    bottom: 127px;
    right: 208px;
    z-index: 1000;
    background-color: white;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.11);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 0px;
    text-align: center;
  }

  @media (max-width: 600px) {
    .header-container {
      padding: 0px;
      height: 100px;
    }
    .header #logo img {
      margin-top: 12px;
    }

    .user-region:hover .lp-mypage-menu-bg {
      top: 88px;
    }

    .tri {
      border-bottom: 4px solid white;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    .table-cell {
      height: 40px;
    }

    .account_outline {
      display: none;
    }
    .btn-top {
      left: 20px;
      bottom: 51px;
    }
  }
  @media (max-width: 320px){
    .table-cell{
      width: 20%;
    }
    .lp-login,.user-region{
      width: 100%;
    }
  }
</style>
